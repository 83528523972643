const imagenes = {
	/**	TERMAS 			**/
	termas1: require("./img/Turismo/Termas/1A2F-Multimedios-4.webp"),
	termas2: require("./img/Turismo/Termas/DSC_0010.webp"),
	termas3: require("./img/Turismo/Termas/DSC_0083.webp"),
	termas4: require("./img/Turismo/Termas/DSC_0090.webp"),
	termas5: require("./img/Turismo/Termas/DSC_0093.webp"),
	termas6: require("./img/Turismo/Termas/WhatsApp Image 2021-07-02 at 07.48.01 (10).webp"),
	termas7: require("./img/Turismo/Termas/WhatsApp Image 2021-07-02 at 07.48.01 (16).webp"),
	termas8: require("./img/Turismo/Termas/WhatsApp Image 2021-07-02 at 07.48.01 (3).webp"),
	termas9: require("./img/Turismo/Termas/termas-5.webp"),
	termas10: require("./img/Turismo/Termas/termas-chajari-piscinas.webp"),
	/**	SANTA ANA 		**/
	santaAna1: require("./img/Turismo/Santa Ana/IMG_4757.webp"),
	santaAna2: require("./img/Turismo/Santa Ana/IMG_4758.webp"),
	santaAna3: require("./img/Turismo/Santa Ana/IMG_4759.webp"),
	santaAna4: require("./img/Turismo/Santa Ana/IMG_4760.webp"),
	santaAna5: require("./img/Turismo/Santa Ana/IMG_4761.webp"),
	santaAna6: require("./img/Turismo/Santa Ana/IMG_4762.webp"),
	santaAna7: require("./img/Turismo/Santa Ana/IMG_4763.webp"),
	santaAna8: require("./img/Turismo/Santa Ana/IMG_4764.webp"),
	/*	LOGO 		**/
	logo1: require("./img/Logos/Hotel Río 1.webp"),
	logo2: require("./img/Logos/Hotel Río 2.webp"),
	logo3: require("./img/Logos/Hotel Río 3.webp"),
	/**	HOTEL 		 **/
	riohetel1: require("./img/Generales/hotel (1).webp"),
	riohetel2: require("./img/Generales/hotel (2).webp"),
	riohetel3: require("./img/Generales/hotel (3).webp"),
	riohetel4: require("./img/Generales/hotel (4).webp"),
	riohetel5: require("./img/Generales/hotel (5).webp"),
	riohetel6: require("./img/Generales/hotel (6).webp"),
	riohetel7: require("./img/Generales/hotel (7).webp"),
	riohetel8: require("./img/Generales/hotel (8).webp"),
	riohetel9: require("./img/Generales/hotel (9).webp"),
	riohetel10: require("./img/Generales/hotel (10).webp"),
	riohetel11: require("./img/Generales/hotel (11).webp"),
	riohetel12: require("./img/Generales/hotel (12).webp"),
	riohetel13: require("./img/Generales/hotel (13).webp"),
	riohetel14: require("./img/Generales/hotel (14).webp"),
	riohetel15: require("./img/Generales/hotel (15).webp"),
	riohetel16: require("./img/Generales/hotel (16).webp"),
	riohetel17: require("./img/Generales/hotel (17).webp"),
	riohetel18: require("./img/Generales/hotel (18).webp"),
	riohetel19: require("./img/Generales/hotel (19).webp"),
	riohetel20: require("./img/Generales/hotel (20).webp"),
	riohetel21: require("./img/Generales/hotel (21).webp"),
	riohetel22: require("./img/Generales/hotel (22).webp"),
	riohetel23: require("./img/Generales/hotel (23).webp"),
	riohetel24: require("./img/Generales/hotel (24).webp"),
	riohetel25: require("./img/Generales/hotel (25).webp"),
	riohetel26: require("./img/Generales/hotel (26).webp"),
	riohetel27: require("./img/Generales/hotel (27).webp"),
	riohetel28: require("./img/Generales/hotel (28).webp"),
	riohetel29: require("./img/Generales/hotel (29).webp"),
	riohetel30: require("./img/Generales/hotel (30).webp"),
	riohetel31: require("./img/Generales/hotel (31).webp"),
	/** CLASICA			**/
	clasica1: require("./img/Habitaciones/Clásica/clasica (1).webp"),
	clasica2: require("./img/Habitaciones/Clásica/clasica (2).webp"),
	clasica3: require("./img/Habitaciones/Clásica/clasica (3).webp"),
	clasica4: require("./img/Habitaciones/Clásica/clasica (4).webp"),
	clasica5: require("./img/Habitaciones/Clásica/clasica (5).webp"),
	clasica6: require("./img/Habitaciones/Clásica/clasica (6).webp"),
	/**	FAMILIAR		 **/
	familiar1: require("./img/Habitaciones/Familiar/familiar (1).webp"),
	familiar2: require("./img/Habitaciones/Familiar/familiar (2).webp"),
	familiar3: require("./img/Habitaciones/Familiar/familiar (3).webp"),
	familiar4: require("./img/Habitaciones/Familiar/familiar (4).webp"),
	/**		PREMIUM		 **/
	premium1: require("./img/Habitaciones/Premium/premium (1).webp"),
	premium2: require("./img/Habitaciones/Premium/premium (2).webp"),
	premium3: require("./img/Habitaciones/Premium/premium (3).webp"),
	premium4: require("./img/Habitaciones/Premium/premium (4).webp"),
	premium5: require("./img/Habitaciones/Premium/premium (5).webp"),
	premium6: require("./img/Habitaciones/Premium/premium (6).webp"),
	/**		Suit		 **/

	suit1: require("./img/Habitaciones/Suite/suit (1).webp"),
	suit2: require("./img/Habitaciones/Suite/suit (2).webp"),
	suit3: require("./img/Habitaciones/Suite/suit (3).webp"),
	suit4: require("./img/Habitaciones/Suite/suit (4).webp"),
	suit5: require("./img/Habitaciones/Suite/suit (5).webp"),
	suit6: require("./img/Habitaciones/Suite/suit (6).webp"),
	suit7: require("./img/Habitaciones/Suite/suit (7).webp"),
	/**		Superior		 **/

	superior1: require("./img/Habitaciones/Superior/superior (1).webp"),
	superior2: require("./img/Habitaciones/Superior/superior (2).webp"),
	superior3: require("./img/Habitaciones/Superior/superior (3).webp"),
	superior4: require("./img/Habitaciones/Superior/superior (4).webp"),
};
export default imagenes;

import React from "react";
import { Carousel } from "react-bootstrap";
import imagenes from "../../assets/Imagenes";
import "../../styles/styles.css";

function Header() {
	return (
		<div className="carousel-container">
			{/* Contenido superpuesto */}
			<div className="carousel-text">
				<div className="carousel-overlay">
					<h1>Hotel Rio Web</h1>
					<p className="carousel-description">
						En nuestro hotel, cada detalle está pensado para
						brindarle una estadía cómoda y placentera. Ya sea que
						busque relajarse en un entorno tranquilo, disfrutar de
						una escapada en pareja o compartir momentos en familia,
						aquí encontrará el equilibrio perfecto entre confort y
						calidez. Contamos con habitaciones acogedoras, un
						servicio atento y una ubicación privilegiada para que
						aproveche al máximo su visita. Lo esperamos para hacer
						de su viaje una experiencia inolvidable.
					</p>
					<p className="carousel-description-2">
						En nuestro hotel, cada detalle está pensado para
						ofrecerle una estadía cómoda y placentera. Ya sea para
						relajarse, disfrutar de una escapada en pareja o
						compartir momentos en familia, encontrará el equilibrio
						perfecto entre confort y calidez. Contamos con
						habitaciones acogedoras, un servicio atento y una
						ubicación privilegiada para que aproveche su visita. Lo
						esperamos para hacer de su viaje una experiencia
						inolvidable.
					</p>
				</div>
			</div>

			{/* Carrusel */}
			<Carousel className="carousel-content" interval={4000} fade>
				<Carousel.Item>
					<img
						src={imagenes.riohetel1}
						alt="Hotel Río"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={imagenes.riohetel10}
						alt="Suite"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={imagenes.riohetel2}
						alt="Habitación Premium"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={imagenes.riohetel3}
						alt="Habitación Clásica"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={imagenes.riohetel4}
						alt="Habitación Clásica"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>{" "}
				<Carousel.Item>
					<img
						src={imagenes.riohetel10}
						alt="Habitación Clásica"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>{" "}
				<Carousel.Item>
					<img
						src={imagenes.riohetel11}
						alt="Habitación Clásica"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>{" "}
				
				<Carousel.Item>
					<img
						src={imagenes.riohetel14}
						alt="Habitación Clásica"
						className="d-block w-100 carousel-image"
					/>
				</Carousel.Item>
			</Carousel>
		</div>
	);
}

export default Header;

import "./habitaciones.css";
import React from "react";
import Carrusel from "../../components/Carrusel/Carrusel";
import imagenes from "../../assets/Imagenes";

const habitaciones = [
	{
		titulo: "Clásica",
		descripcion:
			"Disfrute de una cómoda y acogedora habitación de 12m² con una hermosa vista a través de su amplia ventana. Equipada con un colchón de resortes para un descanso placentero, Smart TV de 32 pulgadas, aire acondicionado frío/calor y un práctico escritorio. Además, cuenta con un secador de pelo y un somier de 130cm x 180cm, ideal para su confort. Todo lo que necesita para una estadía relajante y placentera.",
		imagenes: [
			imagenes.clasica1,
			imagenes.clasica2,
			imagenes.clasica3,
			imagenes.clasica4,
			imagenes.clasica5,
			imagenes.clasica6,
		],
	},
	{
		titulo: "Prémium",
		descripcion: "Amplia habitación de 20m² diseñada para su comodidad y privacidad. Disfrute de un acogedor espacio con un estar amplio, colchón de resortes para un descanso placentero y la opción de una cama matrimonial king o dos individuales de 90cm x 190cm. Equipada con Smart TV de 32 pulgadas, aire acondicionado frío/calor, frigobar y secador de pelo, esta habitación es ideal para una estadía confortable y relajante.",
		imagenes: [imagenes.premium1, imagenes.premium2, imagenes.premium3, imagenes.premium4, imagenes.premium5, imagenes.premium6],
	},
	{
		titulo: "Superior",
		descripcion: "Nuestra habitación SUPERIOR ofrece 18m² de confort y luminosidad, gracias a su amplia ventana que crea un ambiente cálido y agradable. Equipada con un colchón de resortes para un descanso inigualable, Smart TV de 32 pulgadas, aire acondicionado frío/calor y un práctico escritorio. Además, puede optar por una cama matrimonial king o dos individuales de 90cm x 190cm. Un espacio ideal para relajarse y disfrutar de una estadía placentera.",
		
		imagenes: [imagenes.superior1, imagenes.superior2, imagenes.superior3, imagenes.superior4],
	},
	{
		titulo: "Suite",
		descripcion: "Disfrute de una amplia y elegante habitación, decorada con un estilo sobrio y moderno para brindar el máximo confort. Sus ventanales con vistas a la ciudad inundan el espacio de luz natural, creando un ambiente cálido y acogedor. Cuenta con dos ambientes bien distribuidos, un amplio vestidor y una relajante bañera con hidromasaje. Además, está equipada con Smart TV de 32 pulgadas, dos aires acondicionados frío/calor, mesa con sillas, frigobar, vajilla, hervidor eléctrico y secador de pelo. Puede optar por una cama matrimonial king o dos individuales de 90cm x 190cm. Un espacio diseñado para una estadía inolvidable.",

		imagenes: [imagenes.suit1, imagenes.suit2, imagenes.suit3, imagenes.suit4, imagenes.suit5, imagenes.suit6, imagenes.suit7],
	},
	{
		titulo: "Familiar",
		descripcion: "Disfrute de una confortable y espaciosa habitación, diseñada con una ambientación elegante y ventanales que ofrecen vistas a la ciudad, llenando el espacio de luz natural. Cuenta con dos ambientes bien distribuidos para mayor comodidad, colchones de resortes y un baño con ducha amplia. Además, está equipada con Smart TV de 32 y 42 pulgadas, dos aires acondicionados frío/calor, frigobar y secador de pelo. Cuenta con una cama matrimonial king y dos individuales de 90cm x 190cm. Un espacio ideal para una estadía placentera y relajante.",
		
		imagenes: [
			imagenes.familiar1,
			imagenes.familiar2,
			imagenes.familiar3,
			imagenes.familiar4,
		],
	},
];

function Habitaciones() {
	return (
		<>
			<h2 className="text-center" id="habitaciones">
				Habitaciones
			</h2>
			<div className="container-fluid landing-container">
				{habitaciones.map((habitacion, index) => (
					<div
						key={index}
						className={`contenedor-general-habitaciones ${
							index % 2 === 0 ? "bg-light" : ""
						}`}
					>
						<div className="contendor-datos-imagenes">
							<div className="contendor-datos-habitaciones">
								<h4 className="titulos-habitaciones" >{habitacion.titulo}</h4>
								<p className="texto-habitaciones">{habitacion.descripcion}</p>
						
								<button className="btn btn-primary btn-reservar reservar-xl">
									Reservar
								</button>
							</div>
							<Carrusel
								images={habitacion.imagenes}
								className="imagenes-habitacion"
							/>
						</div>
						<button className="btn btn-primary btn-reservar reserva-sm">
							Reservar
						</button>
					</div>
				))}
			</div>
		</>
	);
}

export default Habitaciones;

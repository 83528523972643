import React from "react";
import "./destino.css"; // Importa el archivo CSS
import imagenes from "../../assets/Imagenes"; // Importa las imágenes
import Carrusel from "../../components/Carrusel/Carrusel";
import { FaMapMarkerAlt } from "react-icons/fa"; // Importa el icono de ubicación

const Destinos = () => {
	const destinos = [
		{
			nombre: "Termas Chajarí",
			descripcion:
				"En el complejo termal de Chajarí podrán relajarse y disfrutar de las diversas piscinas de aguas termales y también conectar con la naturaleza ya que cuenta con un parque de más de 40 hectáreas, en el que se encuentra una reserva natural y un espejo de agua, además de las diversas zonas verdes.",
			imagen: [
				imagenes.termas3,
				imagenes.termas1,
				imagenes.termas2,
				imagenes.termas4,
				imagenes.termas5,
				imagenes.termas6,
				imagenes.termas7,
				imagenes.termas8,
				imagenes.termas9,
				imagenes.termas10,
			],
			alt: "Vista de las Termas Chajarí",
			link: "https://www.google.com/maps/dir//Termas+de+Chajarí,+Chajarí,+Entre+Ríos",
		},

		{
			nombre: "Camping Santa Ana",
			descripcion:
				"Destino ideal para quienes buscan disfrutar de la naturaleza y actividades al aire libre. Este camping ofrece amplias playas en el Lago Salto Grande, proporcionando un entorno perfecto para relajarse y disfrutar del agua. Entre sus instalaciones, el camping cuenta con áreas de acampe equipadas con iluminación, parrillas, servicios de seguridad, baños, piletas de lavado y agua corriente. El entorno natural se complementa con un parque forestal excepcional, brindando a los visitantes múltiples opciones para conectarse con la naturaleza.",
			imagen: [
				imagenes.santaAna1,
				imagenes.santaAna2,
				imagenes.santaAna3,
				imagenes.santaAna4,
				imagenes.santaAna5,
				imagenes.santaAna6,
				imagenes.santaAna7,
				imagenes.santaAna8,
			],
			alt: "Camping Santa Ana Chajarí",
			link: "https://www.google.com/maps/dir//Camping+Santa+Ana,+Chajarí,+Entre+Ríos",
		},
	];

	return (
		<>
		<h2 id="destinos" className="turismo-h3">Turismo</h2>
			{destinos.map((destino, index) => (
				<section key={index}  className="destino">
					<article className="bg-light destino-articulo">
						<h3 className="destino-h3">{destino.nombre}</h3>
						<p className="destino-p">{destino.descripcion}</p>
						<Carrusel images={destino.imagen} className="carrusel-destinos" />
						<a
							href={destino.link}
							target="_blank"
							rel="noopener noreferrer"
							className="destino-a"
						>
							<FaMapMarkerAlt className="icono-ubicacion" /> Ver en Google Maps
						</a>
					</article>
				</section>
			))}
		</>
	);
};

export default Destinos;
